<template>
  <!-- 放置弹出层组件 -->
  <el-dialog
    title="编辑专题分类"
    :visible="editorShowDialog"
    @close="btnCancel"
  >
    <!-- 表单数据 -->
    <el-form
      ref="editorForm"
      :model="field"
      :rules="editorRules"
      label-width="120px"
    >
      <template v-for="item in form_item">
        <el-form-item
          v-if="item.type == 'input'"
          :key="item.id"
          :label="item.label"
          :prop="item.prop"
          :rules="item.rules"
        >
          <el-input
            v-model.trim="field[item.prop]"
            style="width: 80%"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
      </template>
    </el-form>
    <!-- 确定和取消按钮 -->
    <el-row slot="footer" type="flex" justify="center">
      <el-col :span="6">
        <el-button size="small" @click="btnCancel">取消</el-button>
        <el-button type="primary" size="small" @click="editorSubmit"
          >确定</el-button
        >
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { modifyTopicCategory } from '../../../request/http'
export default {
  data() {
    return {
      form_item: []
    }
  },
  props: {
    item: {
      type: Array,
      default: () => []
    },
    editorShowDialog: {
      type: Boolean,
      default: false
    },
    field: {
      type: Object,
      default: () => ({})
    },
    editorRules: {
      type: Object,
      default: () => ({})
    }
  },
  beforeMount() {
    this.form_item = this.item
  },
  methods: {
    // 提交表单验证按钮
    editorSubmit() {
      this.$refs.editorForm.validate(async (valid) => {
        if (valid) {
          const res = await modifyTopicCategory(this.field)
          // 告诉父组件,触发一个自定义事件
          this.$emit('modify')
          this.$emit('update:editorShowDialog', false)
          if (res.data.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('编辑成功')
        }
      })
    },
    // 取消表单验证按钮
    btnCancel() {
      // 关闭弹层
      this.$emit('update:editorShowDialog', false)
      // 清除校验
      this.$refs.editorForm.resetFields()
    }
  }
}
</script>

<style>
</style>