<template>
  <div class="classify_container">
    <!-- 按钮 -->
    <div class="search_btn">
      <el-button
        size="medium"
        type="primary"
        icon="el-icon-arrow-left"
        @click="goBack"
        >返回</el-button
      >
      <el-button
        size="medium"
        type="primary"
        icon="el-icon-plus"
        @click="addClassify"
        >添加专题分类</el-button
      >
    </div>

    <!-- 表格数据区域 -->
    <el-table
      style="width: 100%"
      border
      stripe
      :data="classifyList"
      :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
    >
      <el-table-column
        show-overflow-tooltip
        prop="categoryName"
        label="分类名称"
        align="center"
      ></el-table-column>

      <el-table-column align="center" label="操作" width="251px">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="modify(scope.row)">
            编辑
          </el-button>
          <el-button type="text" size="small" @click="remove(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="classifyInfo.page"
      :limit.sync="classifyInfo.pageSize"
      @pagination="getCategories"
    />

    <add-classify
      :showDialog.sync="showDialog"
      @addSubmit="getCategories"
    ></add-classify>

    <modifyClassify
      :item="form_item"
      :editorShowDialog.sync="editorShowDialog"
      :field="form_field"
      @modify="getCategories"
    ></modifyClassify>
  </div>
</template>

<script>
import { getCategories, delTopicCategory } from '../../request/http'
import addClassify from '../specialTopic/components/addClassify.vue'
import modifyClassify from '../specialTopic/components/modifyClassify.vue'

export default {
  data() {
    return {
      showDialog: false,
      editorShowDialog: false,
      classifyInfo: {
        page: 1,
        pageSize: 10
      },
      total: 0,
      classifyList: [],

      // 编辑备注弹出层组件
      form_item: [
        {
          type: 'input',
          label: '专题名称',
          prop: 'categoryName',
          rules: [
            { required: true, message: '专题分类不能为空', trigger: 'blur' }
          ]
        }
      ],
      // 编辑弹出层组件数据
      form_field: {
        categoryId: '', //id
        categoryName: '' //专题名称
      }
    }
  },
  created() {
    this.getCategories() //获取专题分类列表
  },
  components: {
    addClassify,
    modifyClassify
  },
  methods: {
    async getCategories() {
      const res = await getCategories(this.classifyInfo)
      console.log(res)
      if (res.code !== 0) {
        return this.$message.error(res.code)
      }
      this.classifyList = res.data
      this.total = res.count
    },

    addClassify() {
      this.showDialog = true
    },

    //删除添加专题
    async remove(row) {
      // console.log(row)
      try {
        await this.$confirm('确定要删除吗')
        const res = await delTopicCategory(row.categoryId)
        console.log(res, '***')
        if (res.data.code !== 0) {
          return this.$message.error(res.data.msg)
        }
        this.$message.success('删除成功')
        this.getCategories()
        const totalPage = Math.ceil(
          (this.total - 1) / this.classifyInfo.pageSize
        )
        this.classifyInfo.page =
          this.classifyInfo.page > totalPage
            ? totalPage
            : this.classifyInfo.page
        this.classifyInfo.page =
          this.classifyInfo.page < 1 ? 1 : this.classifyInfo.page
      } catch (error) {
        console.log(error)
      }
    },

    // 编辑
    modify(row) {
      // console.log(row)
      this.editorShowDialog = true
      this.form_field.categoryId = row.categoryId
      this.form_field.categoryName = row.categoryName
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang='scss'>
.classify_container {
  margin: 20px;
  .search_btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>