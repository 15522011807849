<template>
  <!-- 放置弹出层组件 -->
  <el-dialog title="添加专题分类" :visible="showDialog" @close="btnCancel">
    <!-- 表单数据 -->
    <el-form label-width="120px" :model="formData" :rules="rules" ref="addForm">
      <el-form-item label="分类名称" prop="categoryName">
        <el-input
          style="width: 80%"
          placeholder="请输入分类名称"
          v-model.trim="formData.categoryName"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 确定和取消按钮 -->
    <el-row slot="footer" type="flex" justify="center">
      <el-col :span="6">
        <el-button size="small" @click="btnCancel">取消</el-button>
        <el-button type="primary" size="small" @click="btnSubmit"
          >确定</el-button
        >
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
import { topicCategory } from '../../../request/http'

export default {
  data() {
    return {
      // 定义表单数据
      formData: {
        categoryName: '' //分类名称
      },
      //校验规则
      rules: {
        categoryName: [
          { required: true, message: '请输入分类名称', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false
    }
  },
  created() {},
  methods: {
    // 提交表单验证按钮
    btnSubmit() {
      // 校验表单
      this.$refs.addForm.validate(async (valid) => {
        if (valid) {
          const res = await topicCategory(this.formData)
          // 告诉父组件,触发一个自定义事件
          this.$emit('addSubmit')
          this.$emit('update:showDialog', false)
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message.success('添加专题分类成功')
        }
      })
    },
    // 取消表单验证按钮
    btnCancel() {
      // 关闭弹层
      this.$emit('update:showDialog', false)
      // 清除校验
      this.$refs.addForm.resetFields()
    }
  }
}
</script>

<style>
</style>